import React, { useEffect, useState } from "react";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import ReadingCard from "./ReadingCard"
import { useAuth } from "../../util/auth";
import Button from "../Button"
import { useReadingsByWeekSectionandIndex, useSectionsByWeekandIndex, useNotesByWeekSectionIndex } from "../../util/db";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "../../util/router";
function ReadingSection(props) {
  const {weekNumber} = useParams()
  const {sectionNumber} = useParams()
  const {index} = useParams()
  const [previous, setPrevious] = useState([])
  const [next, setNext] = useState([])
  const [isFirst, setFirst] = useState(false)
  const [isLast, setLast] = useState(false)


  const {
    data: reading,
    status: readingStatus,
    error: readingError,
  } = useReadingsByWeekSectionandIndex(parseInt(weekNumber),parseInt(sectionNumber), parseInt(index));

  const {
    data: section,
    status: sectionStatus,
    error: sectionError,
  } = useSectionsByWeekandIndex(parseInt(weekNumber), parseInt(sectionNumber));
  const {
    data: notes,
    status: notesStatus,
    error: notesError
  } = useNotesByWeekSectionIndex(parseInt(weekNumber), parseInt(sectionNumber), parseInt(index));


  function findPrevious(weekData) {
    if (weekData.elements) {
      weekData.elements.map((each, number) => {
        if (each === "reading" && weekData.indices[number] === parseInt(index)) {
          if (number ===0){
            setFirst(true)
          }
          if (number === weekData.elements.length-1){
            setLast(true)
          }
          setPrevious([weekData.elements[number-1], weekData.indices[number-1]])
        }
      })
    }
  }

  function findNext(weekData) {
    if (weekData.elements) {
      weekData.elements.map((each, number) => {
        if (each === "reading" && weekData.indices[number] === parseInt(index)) {
          setNext([weekData.elements[number+1], weekData.indices[number+1]])
        }
      })
    }
  }
  useEffect(() => {
    if(sectionStatus === "success") {
      findPrevious(section[0])
      findNext(section[0])
    }
  }, [section, index]);

  return (
    <>{(readingStatus==="success" && reading && reading.length ===1)&&(
      sectionStatus==="success" && section && section.length ===1)&&(
        notesStatus==="success" && notes)&&(


        <>
        <Section
        size={props.size}
        bgColor={props.bgColor}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        textColor={props.textColor}
      >
        <div className="container">
          <SectionHeader
            title={reading[0].title}
            subtitle={reading[0].description}
            strapline={props.strapline}
            className="text-center"
          />
        </div>
      </Section>
      <Section
      size={props.size}
      bgColor={"white"}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      >
      <div className="container">
        <div className="grid grid-cols-1">
          <>
            <ReadingCard notes = {notes} paragraphs = {reading[0].paragraphs} id = {reading[0].id}/> 
            <div className="grid grid-cols-2 space-x-4">
            {(isFirst ===false)?<Button component = {Link} to = {`/${previous[0]}/${weekNumber}/${sectionNumber}/${previous[1]}`}>Previous Item</Button>:<Button component = {Link} to = {`/week/${weekNumber}/${sectionNumber}`}>Back To Section</Button>}
            {(isLast ===false)?<Button component = {Link} to = {`/${next[0]}/${weekNumber}/${sectionNumber}/${next[1]}`}>Next Item</Button>:<Button component = {Link} to = {`/week/${weekNumber}/${sectionNumber}`}>Back To Section</Button>}
            </div>
          </>
        </div>

      </div>
      </Section>
        </>
        )}</>
  );
}

export default ReadingSection;
