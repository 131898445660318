import React from "react";
import VideoCard from "./VideoCard";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import Button from "../Button";
import { Link } from "../../util/router";

import { useSectionsByWeekandIndex, useVideoByWeekSectionandIndex } from "../../util/db";

const VideoSection = (props) => {
    const {weekNumber} = useParams()
    const {sectionNumber} = useParams()
    const {index} = useParams()
    const [previous, setPrevious] = useState([])
    const [next, setNext] = useState([])
    const [isFirst, setFirst] = useState(false)
    const [isLast, setLast] = useState(false)

    const {
        data: video,
        status: videoStatus,
        error: videoError,
      } = useVideoByWeekSectionandIndex(parseInt(weekNumber),parseInt(sectionNumber), parseInt(index)); 

    const {
        data: section,
        status: sectionStatus,
        error: weekError,
    } = useSectionsByWeekandIndex(parseInt(weekNumber), parseInt(sectionNumber));
  

      function findPrevious(weekData) {
        if (weekData.elements) {
          weekData.elements.map((each, number) => {
            if (each === "video" && weekData.indices[number] === parseInt(index)) {
              if (number ===0){
                setFirst(true)
              }
              if (number === weekData.elements.length-1){
                setLast(true)
              }
              setPrevious([weekData.elements[number-1], weekData.indices[number-1]])
            }
          })
        }
      }
    
      function findNext(weekData) {
        if (weekData.elements) {
          weekData.elements.map((each, number) => {
            if (each === "video" && weekData.indices[number] === parseInt(index)) {
              setNext([weekData.elements[number+1], weekData.indices[number+1]])
            }
          })
        }
      }

      useEffect(() => {
        if(sectionStatus === "success") {
          findPrevious(section[0])
          findNext(section[0])
        }
      }, [section, index, weekNumber]);
    return (

        <>
        {((video && videoStatus === "success" && video.length === 1)&&
        (sectionStatus==="success" && section && section.length ===1))&&(
            <>
            <Section
            size={props.size}
            bgColor={props.bgColor}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            textColor={props.textColor}
            >
            <div className="container">
            <SectionHeader
                title={`Week ${weekNumber}: ${video[0].title}`}
                subtitle={video[0].description}
                strapline={props.strapline}
                className="text-center"
            />
            </div>
            </Section>
            <Section
            size={props.size}
            bgColor={"white"}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            textColor={props.textColor}
            >
            <div className="container">
            <div className="grid grid-cols-1">
                <>
                <VideoCard code = {video[0].code}/>
                    <div className="grid grid-cols-2 space-x-4">
                    {(isFirst ===false)?<Button component = {Link} to = {`/${previous[0]}/${weekNumber}/${sectionNumber}/${previous[1]}`}>Previous Item</Button>:<Button component = {Link} to = {`/week/${weekNumber}/${sectionNumber}`}>Back To Week</Button>}
                    {(isLast ===false)?<Button component = {Link} to = {`/${next[0]}/${weekNumber}/${sectionNumber}/${next[1]}`}>Next Item</Button>:<Button component = {Link} to = {`/week/${weekNumber}/${sectionNumber}`}>Back To Week</Button>}
                    </div>
                </>
                </div>
            </div>
            </Section>
            </>
        )}
        </>
    );
  };
  
  export default VideoSection;