import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";

function CourseSection(props) {
  const auth = useAuth();

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="flex flex-wrap">

          <div className="p-4 w-full md:w-1/2">
            <div className="rounded border border-gray-200">
           
            </div>
          </div>

          <div className="p-4 w-full md:w-1/2">
            <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
            
              <h3 className="mb-4">Extra debug info</h3>
              <div>
                You are signed in as <strong>{auth.user.email}</strong>.
              </div>

              {auth.user.stripeSubscriptionId && (
                <>
                  <div>
                    You are subscribed to the{" "}
                    <strong>{auth.user.planId} plan</strong>.
                  </div>
                  <div>
                    Your plan status is{" "}
                    <strong>{auth.user.stripeSubscriptionStatus}</strong>.
                  </div>
                </>
              )}

              <div>
                You can change your account info{` `}
                {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
                in{` `}
                <Link to="/settings/general">settings</Link>.
              </div>

              {!auth.user.stripeSubscriptionId && (
                <div>
                  You can signup for a plan in{" "}
                  <Link to="/pricing">pricing</Link>.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default CourseSection;
