import React from "react";
import ParagraphEdit from "./ParagraphEdit";
import { useAuth } from "../../util/auth";



function ReadingCard(props) {
    const auth = useAuth()
    return (
    <div className="p-4 w-full">
        <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
            <div className="container">
                <div className="grid grid-cols-1">
                    <>
                    {props.paragraphs.map((each, index) => {            
                        return <>{
                        <>
                            {(auth.user.uid==="ZQ8b2qGAi6XT8cv50wFqrU9Mlpo1" || 
                            auth.user.uid === "I5oYuWNUG6bJPzKpIKyBqnakNJj1" ||
                            auth.user.uid == "rP2px073vOdelMx2akcvhvWjeMk2")&&(
                                <ParagraphEdit note = {props.notes.find(obj => obj.paragraph === index)} paragraph = {each} id = {props.id} index = {index} key = {each.paragraph}/>
                            )}
                        <p key = {index}>{each}</p>

                        </>}</>
                    })}
                    </>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ReadingCard;