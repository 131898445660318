
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function QuestionCard({question, index, onCorrectnessChange}) {

    // Function to handle option selection
    const handleOptionChange = (selectedAnswer) => {
        // Assuming you have a way to determine the correct answer, e.g., question.correctAnswer
        const correctness = selectedAnswer === question.correctAnswer;
        onCorrectnessChange([correctness, index]); // Notify the parent about the change
    };

  return (

    <div className="min-w-0">
    <div className="flex items-start gap-x-3">
      <p className="text-sm font-semibold leading-6 text-gray-900">{question.question}</p>
    </div>
    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">

      <div>
    <fieldset>
        <legend className="sr-only">Question</legend>
        <div className="space-y-5">
        
            <div className="relative flex items-start">
            <div className="flex h-6 items-center">
                <input
                id={question.answerOne}
                aria-describedby={`large-description`}
                name={index}
                type="radio"
                onChange={() => handleOptionChange("a")}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
            </div>
            <div className="ml-3 text-sm leading-6">
                <label htmlFor={question.answerOne} className="font-medium text-gray-900">
                {question.answerOne}
                </label>{' '}
            </div>
            </div>

            <div className="relative flex items-start">
            <div className="flex h-6 items-center">
                <input
                id={question.answerTwo}
                aria-describedby={`large-description`}
                name={index}
                type="radio"
                onChange={() => handleOptionChange("b")}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
            </div>
            <div className="ml-3 text-sm leading-6">
                <label htmlFor={question.answerTwo} className="font-medium text-gray-900">
                {question.answerTwo}
                </label>{' '}
            </div>
            </div>

            <div className="relative flex items-start">
            <div className="flex h-6 items-center">
                <input
                id={question.answerThree}
                aria-describedby={`large-description`}
                name={index}
                type="radio"
                onChange={() => handleOptionChange("c")}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
            </div>
            <div className="ml-3 text-sm leading-6">
                <label htmlFor={question.answerThree} className="font-medium text-gray-900">
                {question.answerThree}
                </label>{' '}
            </div>
            </div>

            <div className="relative flex items-start">
            <div className="flex h-6 items-center">
                <input
                id={question.answerFour}
                aria-describedby={`large-description`}
                name={index}
                type="radio"
                onChange={() => handleOptionChange("d")}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
            </div>
            <div className="ml-3 text-sm leading-6">
                <label htmlFor={question.answerFour} className="font-medium text-gray-900">
                {question.answerFour}
                </label>{' '}
            </div>
            </div>

            <div className="relative flex items-start">
            <div className="flex h-6 items-center">
                <input
                id={question.answerFive}
                aria-describedby={`large-description`}
                name={index}
                type="radio"
                onChange={() => handleOptionChange("e")}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
            </div>
            <div className="ml-3 text-sm leading-6">
                <label htmlFor={question.answerFive} className="font-medium text-gray-900">
                {question.answerFive}
                </label>{' '}
            </div>
            </div>
            
        
        </div>
    </fieldset>
      </div>
    </div>
</div>
  )
}