import React from "react";
import "./../styles/global.css";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
// import DashboardPage from "./dashboard";
import ActualDashboardPage from "./actual-dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import CoursePage from "./intro-course";
import ActualCoursePage from "./course"
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import MainNavBar from "../components/Custom/MainNavBar";
import "./../util/analytics";
import { AuthProvider, useAuth } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import WeekPage from "./week";
import QuizPage from "./quiz"
import VideoPage from "./video"
import ReadingPage from "./reading"
import FeedbackPage from "./feedback";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>
            <MainNavBar bgColor="bg-white" />


            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/pricing" component={PricingPage} />

              <Route exact path="/dashboard" component={ActualDashboardPage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              <Route exact path="/purchase/:plan" component={PurchasePage} />

              <Route exact path= "/intro-course" component= {CoursePage}/>

              <Route exact path="/course" component={ActualCoursePage} />

              <Route exact path="/feedback" component={FeedbackPage} />


              {/* <Route path="/week/:weekNumber/:sectionNumber" component = {WeekPage}/> */}
              <Route path="/week/:weekNumber/:sectionNumber"  render={(props) => <WeekPage key={`${props.match.params.weekNumber}-${props.match.params.sectionNumber}`} {...props} />} />


              <Route path="/quiz/:weekNumber/:sectionNumber/:index"  render={(props) => <QuizPage key={`${props.match.params.weekNumber}-${props.match.params.sectionNumber}-${props.match.params.index}`} {...props} />} />


              {/* <Route path="/reading/:weekNumber/:index" component = {ReadingPage}/> */}
              <Route path="/reading/:weekNumber/:sectionNumber/:index"  render={(props) => <ReadingPage key={`${props.match.params.weekNumber}-${props.match.params.sectionNumber}-${props.match.params.index}`} {...props} />} />


              {/* <Route path="/video/:weekNumber/:index" component = {VideoPage}/> */}
              <Route path="/video/:weekNumber/:sectionNumber/:index"  render={(props) => <VideoPage key={`${props.match.params.weekNumber}-${props.match.params.sectionNumber}-${props.match.params.index}`} {...props} />} />



              
              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>
{/* 
            // <Footer
            //   size="md"
            //   bgColor="bg-white"
            //   bgImage=""
            //   bgImageOpacity={1}
            //   textColor=""
            //   sticky={true}
            // /> */}
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
