import React from "react";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import { Link } from "../../util/router";
import { useAuth } from "../../util/auth";
import { useQuizScoreByOwner, useWeekCompletion, useWeeks } from "../../util/db";
import Button from "../Button";
import Graph from "./Graph";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function ActualDashboardSection(props) {
    const auth = useAuth();
    const statuses = {
        Complete: 'text-green-700 bg-green-50 ring-green-600/20',
        Upcoming: 'text-gray-600 bg-gray-50 ring-gray-500/10',
        "In Progress": 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      }

      const {
        data: weeks,
        status: weeksStatus,
        error: weeksError,
      } = useWeeks();
      const {
        data: weeksData,
        status: weeksDataStatus,
        error: weeksDataError
      } = useWeekCompletion(auth.user.uid)
      const {
        data: quizScores,
        status: quizScoresStatus,
        error: quizScoresError
      } = useQuizScoreByOwner(auth.user.uid)

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-2">
        <div className="flex flex-wrap">
          <div className="px-4 w-full">
            <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
            <h3 className="mb-4">Welcome to your Dashboard!</h3>
            <div>
                You are signed in as <strong>{auth.user.email}</strong>.
              </div>
              {!auth.user.stripeSubscriptionId && (
                <div>
                  You can signup for a plan in{" "}
                  <Link to="/pricing">pricing</Link>.
                </div>
              )}
              <p>
                Here is where you can view your completion progress! You will also be able to view quiz and test
                scores.
              </p>
              <h3 className="mb-4">Here are your quiz scores: </h3>
            {(quizScoresStatus === "success" && quizScores.length > 0) && (
              <>
               <Graph results = {quizScores}></Graph>
              </>
            )}
              
            </div>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="px-4 w-full">
            <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
            <h3 className="mb-4">Your Progress</h3>
              {!auth.user.stripeSubscriptionId && (
                <div>
                  You can signup for a plan in{" "}
                  <Link to="/pricing">pricing</Link>.
                </div>
              )}
              {((weeksStatus ==="success" && weeks && weeks.length > 0)&&(
            (weeksDataStatus === "success" && weeksData)
            ))?(<>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 h-full">
      <nav className="flex flex-1 flex-col">
            <ul className="-mx-2 space-y-1">
              {weeks.map((week, index) => (
                <li key={week.title}>
                  {
                    <a
                      href={`/week/${week.number}/0`}
                      className={classNames(
                        week.number === props.weekNumber ? 'bg-gray-100' : 'hover:bg-gray-100',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700'
                      )}
                    >
                      {week.title}
                      <p
                        className={classNames(
                        statuses[weeksData.array[index]],
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                        )}
                      >
                        {weeksData.array[index]}
                      </p>
                    </a>
                  }
                </li>
              ))}
            </ul>
      </nav>
      <Button component = {Link} to = "/course"  variant={"light"}
>
        
        Go to Course</Button>

    </div>
                  </>
            ):(<>
            {(!weeksData && weeksDataStatus==="success")&&(<><h3>Haven't started yet?</h3>
            <Button component = {Link} variant="light" to = "/course">Click here to get started</Button></>)}
            </>)}          
            </div>
          </div>
        </div>
        </div>
      </div>
    </Section>
  );
}

export default ActualDashboardSection;
