import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useWeekCompletion, useWeeks } from '../../util/db'
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { useAuth } from '../../util/auth'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function WorkingSideBar(props) {

  const auth = useAuth()

  const {
    data: weeksData,
    status: weeksDataStatus,
    error: weeksDataError
  } = useWeekCompletion(auth.user.uid)

  const {
    data: weeks,
    status: weeksStatus,
    error: weeksError,
  } = useWeeks();

  const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    Upcoming: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    "In Progress": 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  }
  return (
    <>
    {((weeksStatus ==="success" && weeks && weeks.length > 0)&&(
      (weeksDataStatus === "success" && weeksData)
    ))&&(
      <>
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 h-full">
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {weeks.map((week, index) => (
                <li key={week.title}>
                  {
                    <a
                      href={`/week/${week.number}/0`}
                      className={classNames(
                        week.number === props.weekNumber ? 'bg-gray-100' : 'hover:bg-gray-100',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700'
                      )}
                    >
                      <DocumentDuplicateIcon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                      {week.title}
                      <p
                        className={classNames(
                        statuses[weeksData.array[index]],
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                        )}
                      >
                        {weeksData.array[index]}
                      </p>
                    </a>
                  }
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
      </>
    )}
    </>
  )
}
