import React from'react';
import Meta from '../components/Meta';
import { requireAuth } from "./../util/auth";
import CourseSection from "./../components/CourseSection"

function CoursePage(props) {
  return (
    <>
      <Meta title="Course" />
      <CourseSection 
        title="Intor to Dental Assisting Course"
        subtitle=""
        strapline="Dental Assistant Training Center"
        size="sm" //
        bgColor="bg-light_pink"
      /> 
    </>
  );
}

export default requireAuth(CoursePage);
