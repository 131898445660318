import React from "react";
import {
  CubeTransparentIcon,
  CurrencyDollarIcon,
  ClockIcon
} from "@heroicons/react/24/solid";

import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { HeartIcon } from "@heroicons/react/20/solid";

function HeroSection2(props) {
  const items = [
    {
      title: "Affordable",
      description:
        "We believe in empowering your future without imposing financial strain—our course is priced affordably, opening the doors to a rewarding career in dental assisting for everyone.",
      icon: CurrencyDollarIcon,
    },
    {
      title: "Efficient",
      description:
        "We believe in accessible, impactful learning—our ten-week course is designed to fit seamlessly into your life, ensuring you can advance your career without putting it on hold.",
      icon: ClockIcon,
    },
    {
      title: "Authentic",
      description: "We believe in the power of peer-driven education. Our course, created by dental assistants for dental assistants, focuses on practical skills and insights that pave the way for professional success.",
      icon: HeartIcon,
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <div>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
            className="lg:w-1/2"
          />
        </div>
        <div class="flex w-full space-x-4">
    <div class="w-3/4 flex justify-center items-center">
        <img src={props.leftImage} alt="" class="w-full h-auto object-cover rounded-lg" />
    </div>
    <div class="w-1/3 flex justify-center items-center">
        <img src={props.rightImage} alt="" class="w-full h-auto object-cover rounded-lg" />
    </div>
</div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
          {items.map((item, index) => (
            <div key={index}>
              <h3 className="flex items-center space-x-2 text-lg font-bold uppercase tracking-wide mb-2">
                <item.icon
                  className={
                    "inline-block w-5 h-5" +
                    (item.iconColor ? ` ${item.iconColor}` : "")
                  }
                />
                <span>{item.title}</span>
              </h3>
              <p className="leading-relaxed text-gray-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default HeroSection2;
