import React from "react";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import { useAuth } from "../../util/auth";
import QuestionCard from "./QuestionCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../Button";
import { Link } from "../../util/router";
import { useState, useEffect } from "react";
import { useQuestionsByWeekSectionAndIndex,createQuizScore, useSectionsByWeekandIndex, useQuizzesByWeekandSection } from "../../util/db";
import QuestionEdit from "./QuestionEdit";

function QuizSection(props) {
  const auth = useAuth()
  const {weekNumber} = useParams()
  const {sectionNumber} = useParams()
  const {index} = useParams()
  const [previous, setPrevious] = useState([])
  const [next, setNext] = useState([])
  const [isFirst, setFirst] = useState(false)
  const [isLast, setLast] = useState(false)

  const testData = {
    array: ["Complete", "In Progress", "Upcoming"]
  }

  const {
    data: questions,
    status: questionsStatus,
    error: questionsError,
  } = useQuestionsByWeekSectionAndIndex(parseInt(weekNumber),parseInt(sectionNumber), parseInt(index));  
  const {
    data: section,
    status: sectionStatus,
    error: sectionError,
  } = useSectionsByWeekandIndex(parseInt(weekNumber), parseInt(sectionNumber));
  const {
    data: quizzes,
    status: quizzesStatus,
    error: quizzesError,
  } = useQuizzesByWeekandSection(parseInt(weekNumber), parseInt(sectionNumber));

  const [correctnessArray, setCorrectnessArray] = useState([])
  const [score, setScore] = useState(0)
  const [disabled, setDisabled] = useState(false)

  async function calculateAndPostScore(array){
    setScore(calculateScore(array))
    try {
      await createQuizScore({
        owner: auth.user.uid,
        section:parseInt(sectionNumber),
        index: parseInt(index),
        week: parseInt(weekNumber),
        score: calculateScore(array) * 100
      });
      // After all data is posted successfully, alert the page
      alert(`Quiz posted with a score of ${calculateScore(array)*100}%`)
    } catch (error) {
      console.error("Failed to post week data", error);
      // Handle errors or failed posting if needed
    }

  }

  function calculateScore(array){
    var sum = 0
    for(var i = 0; i < array.length; i++) {
      if (array[i]){
        sum +=1
      }
    }
    return sum/array.length
  }


  const handleCorrectnessChange = (isCorrect) => {
    updateCorrectnessAtIndex(isCorrect[1], isCorrect[0])
  };

  const updateCorrectnessAtIndex = (index, value) => {
    setCorrectnessArray(current =>
      current.map((item, i) => i === index ? value : item)
    );
  };

  function findPrevious(weekData) {
    if (weekData.elements) {
      weekData.elements.map((each, number) => {
        if (each === "quiz" && weekData.indices[number] === parseInt(index)) {
          if (number ===0){
            setFirst(true)
          }
          if (number === weekData.elements.length-1){
            setLast(true)
          }
          setPrevious([weekData.elements[number-1], weekData.indices[number-1]])
        }
      })
    }
  }

  function findNext(weekData) {
    if (weekData.elements) {
      weekData.elements.map((each, number) => {
        if (each === "quiz" && weekData.indices[number] === parseInt(index)) {
          setNext([weekData.elements[number+1], weekData.indices[number+1]])
        }
      })
    }
  }
  useEffect(() => {
    if(sectionStatus === "success") {
      findPrevious(section[0])
      findNext(section[0])
    }
  }, [section, index, weekNumber]);

  return (
    <>
    {useEffect(() => {
    // This code runs only when `questions` changes.
    if (questions){
      setCorrectnessArray(questions.map(() => false));
    }
  }, [questions])}
    {((questionsStatus === "success") && (questions) && (questions.length > 0)&&
    (sectionStatus === "success" && section && section.length === 1)&&(
    (quizzesStatus === "success" && quizzes && quizzes.length >0)))&&(


<>
<Section
  size={props.size}
  bgColor={props.bgColor}
  bgImage={props.bgImage}
  bgImageOpacity={props.bgImageOpacity}
  textColor={props.textColor}
>
  <div className="container">
    <SectionHeader
      title={`Quiz: ${quizzes[index].title}`}
      subtitle={"Good Luck! Also, don't navigate away from this page, the result won't be saved unless you submit the quiz!"}
      strapline={props.strapline}
      className="text-center"
    />
  </div>
</Section>
<Section
size={props.size}
bgColor={"white"}
bgImage={props.bgImage}
bgImageOpacity={props.bgImageOpacity}
textColor={props.textColor}
>
<div className="container">
  <div className="grid grid-cols-1">
  <ul role="list" className="divide-y divide-gray-100">
    {questions.map((question, key) => (
      <li key={key} className="flex items-center py-5">
        {(auth.user.uid==="ZQ8b2qGAi6XT8cv50wFqrU9Mlpo1" || 
          auth.user.uid === "I5oYuWNUG6bJPzKpIKyBqnakNJj1" ||
          auth.user.uid == "rP2px073vOdelMx2akcvhvWjeMk2")&&(
          <QuestionEdit question = {question}/>
        )}
        <QuestionCard key = {key} question = {question} index={key} onCorrectnessChange={handleCorrectnessChange}/>
      </li>
    ))}
  </ul>
  <Button className="my-4" disabled = {disabled} onClick = {() => {
    calculateAndPostScore(correctnessArray)
    setDisabled(true)
  }}>Submit</Button>
        <div className="grid grid-cols-1">
          <>
            <div className="grid grid-cols-2 space-x-4">
            {(isFirst ===false)?<Button component = {Link} to = {`/${previous[0]}/${weekNumber}/${sectionNumber}/${previous[1]}`}>Previous Item</Button>:<Button component = {Link} to = {`/week/${weekNumber}/${sectionNumber}`}>Back To Week</Button>}
            {(isLast ===false)?<Button component = {Link} to = {`/${next[0]}/${weekNumber}/${sectionNumber}/${next[1]}`}>Next Item</Button>:<Button component = {Link} to = {`/week/${weekNumber}/${sectionNumber}`}>Back To Week</Button>}
            </div>
          </>
      </div>
  </div>
</div>
</Section>
</>

    )}
  </>
  );
}

export default QuizSection;
