import React, { useState } from "react";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import Button from "../Button"
import { Link } from "../../util/router";
import { useAuth } from "../../util/auth";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useWeekCompletion, useWeeks, createIndividualSectionData, createWeekCompletion, createSectionData, useSections } from "../../util/db";
import { elements } from "chart.js";


const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  Upcoming: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  "In Progress": 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

function classNames(...classes) {


  return classes.filter(Boolean).join(' ')
}
function CourseSection(props) {
  const auth = useAuth();
  const [isDisabled, setDisabled] = useState(false)

  const {
    data: weekData,
    status: weekDataStatus,
    error: weekDataError
  } = useWeekCompletion(auth.user.uid)

  const {
    data: weeks,
    status: weeksStatus,
    error: weeksError,
  } = useWeeks();
  const {
    data: sections,
    status: sectionsStatus,
    error: sectionsError,
  } = useSections();

  async function postAllWeeksData(){
    var weekArray = []
    var allArrays = []
    var sectionDataArray= []
    if (weeks && weeks.length > 0 && sections && sections.length > 0) {
      var currentWeek = 1
      var sectionArray = []

      sections.map((section, index) => {
        if (section.week === currentWeek) {
          sectionArray.push("Upcoming")
        } else if (section.week !== currentWeek) {
          sectionDataArray.push({
            week: currentWeek,
            owner: auth.user.uid,
            array: sectionArray
          })
          currentWeek = section.week
          sectionArray = ["Upcoming"]
        }

        var temp = []
        section.elements.map((element,index) => {
          temp.push("Upcoming")
          return 0
        })
        allArrays.push({
        "array": temp,
        "owner": auth.user.uid,
        "week": section.week,
        "index": section.index
      })
      return 0
      })
      sectionDataArray.push({
        week: currentWeek,
        owner: auth.user.uid,
        array: sectionArray
      })
    
      weeks.map((week, index) => {
      weekArray.push("Upcoming")
      return 0
    })

    //Make sure ID is auth.user.uid
    var formattedWeeks = {
      array: weekArray
    }

    try {
      await createWeekCompletion(auth.user.uid, formattedWeeks);
      for (const each of allArrays) {
        await createIndividualSectionData(each);
      }
      for (const item of sectionDataArray) {
        await createSectionData(item);
      }
      // After all data is posted successfully, reload the page
      window.location.reload();
    } catch (error) {
      console.error("Failed to post week data", error);
      // Handle errors or failed posting if needed
    }

  }
  }
  return (
    <>
    {((weeksStatus === "success" && weeks && weeks.length>0)&&
    (weekDataStatus === "success" && weekData)
    )&&(
      <>    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        
      </div>
    </Section>
    <Section
    size={props.size}
    bgColor={"white"}
    bgImage={props.bgImage}
    bgImageOpacity={props.bgImageOpacity}
    textColor={props.textColor}
  >
    <div className="container">
    <div className="space-y-8 container">
      {weeks.map((week, index) => 
        <div key = {index} className="grid grid-cols-1">
          <Button 
            component = {Link}
            to = {`/week/${week.number}/0`}
            variant = "simple"
            size="xl"
            endIcon={
              <ArrowRightIcon className="opacity-1000 inline-block w-5 h-5" />
            }
          >
            <div className="flex items-center">
            <span>Week {week.number}: {week.title}</span>
                <p
                  className={classNames(
                    statuses[weekData.array[index]],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ml-2'
                  )}
                >
                  {weekData.array[index]}
                </p>
            </div>
          </Button>
          
        </div>
      )}
    </div>
    </div>
  </Section></>
    )}
    {((weeksStatus === "success" && weeks && weeks.length>0)&&
     (weekDataStatus === "success") && weekData===null)&&(
     <>    
     <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={"Looks like you are new?"}
          subtitle={"Click the button below to get started learning!"}
          strapline={props.strapline}
          className="text-center"
        />
      </div>
    </Section>
     <Button disabled = {isDisabled} onClick = {() => {
      postAllWeeksData()
      setDisabled(true)
     }}>Get Started!</Button>
     </>)}
    </>
  );
}

export default CourseSection;
