import React from 'react';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.initializeChart();
  }

  initializeChart() {
    const chartCanvas = this.chartRef.current.getContext('2d');
    
    new Chart(chartCanvas, {
      type: 'line',
      data: {
        labels: this.props.results.map((each, index) => {
            return `Week ${each.week}: Quiz ${each.index+1}`
        }),
        datasets: [
          {
            data: this.props.results.map((each, index) => {
                return each.score
            }),
            label: "Score (%)",
            backgroundColor: 'rgba(255, 165, 0, 0.4)',
            borderColor: 'rgba(255, 165, 0, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.8,
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: 100,
            ticks: {
            stepSize: 10,
            },
          },
          x: {
            display: false,
          }
        },
        plugins: {
            legend: {
              display: true,
            },
        },
      },
    });
  }

  render() {
    return (
          <canvas ref={this.chartRef} />
    );
  }
}
export default Graph;