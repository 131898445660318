import React, { useState } from 'react';
import { createFeedback, useFeedbackByURL } from '../../util/db';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../util/auth';
import { data } from 'autoprefixer';

const FeedBack = (props) => {
    const auth = useAuth()
  const [isOpen, setIsOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [inputValue, setInputValue] = useState("");  // Initialize state with props.paragraph


  const {
    data: feedback,
    error: feedbackError,
    status: feedbackStatus,
  } = useFeedbackByURL(trimUntilSubstring(window.location.href, "e/"))
  const handleInputChange = (event) => {
    setInputValue(event.target.value);  // Update state based on input
  };
  function trimUntilSubstring(s, substring) {
    const index = s.indexOf(substring);
    if (index !== -1) {
      return s.substring(index + substring.length); // Move past the substring
    }
    return s; // Return original string if substring not found
  }

  async function handlePost(feedback) {
    var url = trimUntilSubstring(window.location.href,"e/")
    data = {
        feedback: feedback,
        url: url,
        owner: auth.user.uid,
        email: auth.user.email
    }
    setSubmitDisabled(true);
    await createFeedback(data);
    alert('Successfully Changed!');
    setIsOpen(false);
    setSubmitDisabled(false)
  }

  return (
    <div className="fixed z-10 bottom-10 right-10">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
    <ChatBubbleLeftEllipsisIcon className="inline-block w-10 h-10" />
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
            <div className="bg-white shadow-lg rounded p-6 w-full max-w-6xl h-full md:h-auto overflow-auto">
              <button
                className=" top-3 right-3 text-gray-900 text-xl font-semibold"
                onClick={() => {
                  setSubmitDisabled(false);
                  setIsOpen(false);
                  setInputValue(inputValue)
                }}
              >
                &times;
              </button>
              <div className="flex flex-col space-y-2">
                <div>
                {(feedback && feedbackStatus === "success" && feedback.length>0)&&(
                    <>
                    <div className='font-bold'>Here is what other people have had to say.</div>
                    {feedback.map((each, key) => 
                        <>
                        <div className='font-semibold' key = {key}>
                        <p>{each.email}: </p>
                        </div>
                        <p>{each.feedback}</p>
                        <div class="h-px bg-gray-300 w-full my-4"></div>

                        </>
                    )}</>
                )}
                </div>
                <label>Leave us some feedback for this page!</label>
                <textarea
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}  // Handle input changes
                  className="border border-gray-300 p-2 rounded"
                />
                  <button
                    disabled={submitDisabled}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      handlePost(inputValue);
                    }}
                  >
                    Post FeedBack
                  </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FeedBack;
