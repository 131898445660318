import React, { useState } from 'react';
import { useReadingOnce, updateReading, updateNote, createNote } from '../../util/db';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ParagraphEdit = (props) => {
  var olddata = {}
  var oldNote = {}

  const {weekNumber} = useParams()
  const {sectionNumber} = useParams()
  const {index} = useParams()

  const [isOpen, setIsOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [inputValue, setInputValue] = useState(props.paragraph);  // Initialize state with props.paragraph
  const [noteValue,setNoteValue] = useState(props.note?props.note.note:"")

  const {
    data: oldreading,
    status: oldreadingStatus,
    error: oldreadingError
  } = useReadingOnce(props.id);


  const handleInputChange = (event) => {
    setInputValue(event.target.value);  // Update state based on input
  };
  const handleNoteChange = (event) => {
    setNoteValue(event.target.value);  // Update state based on input
  };

  async function handlePost(id, useIndex, string, old) {
    olddata = old;
    olddata.paragraphs[useIndex] = string;
    setSubmitDisabled(true);
    await updateReading(id, olddata);
    if (props.note) {
      oldNote= props.note
      oldNote["note"]= noteValue
      await updateNote(props.note.id, oldNote)
    } else {
      await createNote({
        note: noteValue,
        week: parseInt(weekNumber),
        section: parseInt(sectionNumber),
        index: parseInt(index),
        paragraph: props.index
      })
    }
    alert('Successfully Changed!');
    setIsOpen(false);
    setSubmitDisabled(false)
  }

  return (
    <div className="relative">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        Edit Paragraph
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
            <div className="bg-white shadow-lg rounded p-6 w-full max-w-6xl h-full md:h-auto overflow-auto">
              <button
                className=" top-3 right-3 text-gray-900 text-xl font-semibold"
                onClick={() => {
                  setSubmitDisabled(false);
                  setIsOpen(false);
                  setInputValue(props.paragraph)
                  setNoteValue(props.note?props.note.note:"")
                }}
              >
                &times;
              </button>
              <div className="flex flex-col space-y-2">
                <h3>This is the actual paragraph.</h3>
                <textarea
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}  // Handle input changes
                  className="border border-gray-300 p-2 rounded"
                />
                <h4>Below is notes. These are only seen by you.</h4>
                <textarea
                  type="text"
                  value={noteValue}
                  onChange={handleNoteChange}  // Handle input changes
                  className="border border-gray-300 p-2 rounded"
                />
                {(oldreadingStatus === "success" && oldreading) && (
                  <button
                    disabled={submitDisabled}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      handlePost(props.id, props.index, inputValue, oldreading);
                    }}
                  >
                    Post Changes to Notes and Paragraph
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ParagraphEdit;
