import React from 'react';
import Meta from '../components/Meta';
import { requireAuth } from "./../util/auth";
import FeedbackCard from '../components/Custom/FeedbackCard';
import { useFeedback } from '../util/db';
function FeedbackPage(props) {

    const {
        data: feedback,
        status: feedbackStatus,
        error:feedbackError
    } = useFeedback()
  return (


    <>
      <Meta title="Feedback" />

      <div className="flex flex-row"> {/* Flex container */}
        <div className="flex-1"> {/* Main content area taking up the remaining space */}
        {(feedbackStatus === "success" && feedback && feedback.length >0)&&(
            feedback.map((each, index) => 
                <FeedbackCard key = {index}description = {each.feedback} url = {each.url} owner = {each.email} id = {each.id}/>
            )
        )}
        </div>
      </div>
    </>
  );
}

export default requireAuth(FeedbackPage);