import React from'react';
import Meta from '../components/Meta';
import { requireAuth } from "./../util/auth";
import CourseSection from "./../components/Custom/CourseView"

function ActualCoursePage(props) {
  return (
    <>
      <Meta title="Course" />
      <CourseSection 
        title="Intro to Dental Assisting Course"
        subtitle="Click on a week to begin."
        strapline="Dental Assistant Training Center"
        size="sm" //
        bgColor="bg-light_pink"
      /> 
    </>
  );
}

export default requireAuth(ActualCoursePage);
