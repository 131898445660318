import React from "react";
import Meta from "./../components/Meta";
import ActualDashboardSection from "./../components/Custom/ActualDashboardSection"
import { requireAuth } from "./../util/auth";

function ActualDashboardPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <ActualDashboardSection
        title="Dashboard"
        subtitle=""
        strapline="Dental Assistant Training Center"
        size="md"
        bgColor="bg-white"
      />
    </>
  );
}

export default requireAuth(ActualDashboardPage);
