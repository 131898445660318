import { CheckIcon } from '@heroicons/react/20/solid'
import { useSectionDataByWeekandOwner } from '../../util/db';
import { useAuth } from '../../util/auth';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SectionNavigationCard(props) {
  const {weekNumber} = useParams()
  const auth = useAuth()
  const {
    data: individualSectionData,
    status: individualSectionDataStatus,
    error: individualSectionDataError,
  } = useSectionDataByWeekandOwner(parseInt(weekNumber), auth.user.uid);

  return (
    <>{(individualSectionDataStatus === "success" && individualSectionData && individualSectionData.length ===1)&&(
      <nav aria-label="Progress">
      <div className="p-4 w-full">
        <div className="p-6 rounded border border-gray-200 max-w-none relative">
          <li role="list" className="flex flex-wrap items-center justify-center w-full">
            {props.elements.map((step, stepIdx) => (
              <li key={step.name} className="flex flex-wrap items-center">
                {individualSectionData[0].array[stepIdx] === "Complete" ? (
                  <>
                    <a
                      href={`/week/${props.weekNumber}/${stepIdx}`}
                      className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900"
                    >
                      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    </a>
                    <span className="mt-2 text-xs whitespace-nowrap overflow-hidden text-ellipsis w-20 text-center">
                      {`Section ${stepIdx+1}`}
                    </span>
                  </>
                ) : (stepIdx === props.current)&&(individualSectionData[0].array[stepIdx] !== "Complete")  ? (
                  <>
                    <a
                      href={`/week/${props.weekNumber}/${stepIdx}`}
                      className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                    >
                      <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                    </a>
                    <span className="mt-2 text-xs whitespace-nowrap overflow-hidden text-ellipsis w-20 text-center">
                    {`Section ${stepIdx+1}`}
                    </span>
                  </>
                ) : (
                  <>
                    <a
                      href={`/week/${props.weekNumber}/${stepIdx}`}
                      className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white"
                    >
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    </a>
                    <span className="mt-2 text-xs whitespace-nowrap w-20 text-center">
                    {`Section ${stepIdx+1}`}
                    </span>
                  </>
                )}
              </li>
            ))}
          </li>
        </div>
      </div>
    </nav>
    )}
    </>

  );
}