import React from "react";
import {
  AdjustmentsVerticalIcon,
  UserGroupIcon,
  ClockIcon,
  CurrencyDollarIcon
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

function FeaturesSection(props) {
  const features = [
    {
      title: "Expert-Led Curriculum",
      description:
        "Unlock the secrets of dental assisting through a curriculum meticulously designed by industry veterans, blending cutting-edge theory with practical, hands-on experience to equip you with the knowledge and skills that set you apart in the dental field.",
      icon: UserGroupIcon,
      iconColor: "orange",
    },
    {
      title: "Flexible Learning schedule",
      description:
        "Embrace the freedom to learn on your terms with our fully online course, offering a flexible schedule that allows you to pursue your studies at your convenience, ensuring that advancing your education never means sacrificing your current job or personal life.",
      icon: ClockIcon,
      iconColor: "red",
    },

    {
      title: "Career Ready",
      description:
        "Our course is meticulously designed to bridge the gap between learning and doing. We ensure that you not only possess the skills and knowledge demanded by the industry but also stand out to employers as a highly competent and job-ready candidate, significantly boosting your employability in the competitive dental assisting job market.",
      icon: CurrencyDollarIcon,
      iconColor: "emerald",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5">
          {features.map((feature, index) => (
            <div
              className="group p-5 transition ease-out duration-200 rounded-2xl text-center"
              key={index}
            >
              <FeatureIcon2
                color={feature.iconColor}
                size="large"
                className="mb-12"
              >
                <feature.icon />
              </FeatureIcon2>
              <h4 className="text-lg font-bold mb-2">{feature.title}</h4>
              <p className="leading-relaxed text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;
