import React, { useState } from 'react';
import { updateSection } from '../../util/db';

const AboutCardEdit = (props) => {
  var olddata = {}
  const [isOpen, setIsOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [inputValue, setInputValue] = useState(props.section?.aboutParagraph);  // Initialize state with props.paragraph

  const handleInputChange = (event) => {
    setInputValue(event.target.value);  // Update state based on input
  };

  async function handlePost(id, old) {
    olddata = old;
    olddata.aboutParagraph = inputValue
    setSubmitDisabled(true);
    await updateSection(id, olddata);
    alert('Successfully Changed!');
    setIsOpen(false);
    setSubmitDisabled(false)
  }

  return (
    <div className="relative">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        Edit Card
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
            <div className="bg-white shadow-lg rounded p-6 w-full max-w-6xl h-full md:h-auto overflow-auto">
              <button
                className=" top-3 right-3 text-gray-900 text-xl font-semibold"
                onClick={() => {
                  setSubmitDisabled(false);
                  setIsOpen(false);
                  setInputValue(props.section.aboutParagraph)
                }}
              >
                &times;
              </button>
              <div className="flex flex-col space-y-2">
                <textarea
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}  // Handle input changes
                  className="border border-gray-300 p-2 rounded"
                />
                  <button
                    disabled={submitDisabled}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      handlePost(props.section.id, props.section);
                    }}
                  >
                    Make Change
                  </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AboutCardEdit;
