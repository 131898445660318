import React, { useState } from 'react';
import { updateQuiz, updateReading } from '../../util/db';

const QuizCardEdit = (props) => {
    var olddata = {}
    const [isOpen, setIsOpen] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [inputValue, setInputValue] = useState([props.quiz.title, props.quiz.description]);  // Initialize state with props.paragraph
  
    const handleInputChange = (index, event) => {
      const newInputValues = [...inputValue]; // Clone the existing array
      newInputValues[index] = event.target.value; // Update the value at the specific index
      setInputValue(newInputValues); // Set the new array to state
    };
  
    async function handlePost(id, old) {
      olddata = old;
      olddata.title = inputValue[0]
      olddata.description = inputValue[1]
      setSubmitDisabled(true);
      await updateQuiz(id, olddata);
      alert('Successfully Changed!');
      setIsOpen(false);
      setSubmitDisabled(false)
    }

  return (
    <div className="relative">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        Edit Card
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
            <div className="bg-white shadow-lg rounded p-6 w-full max-w-6xl h-full md:h-auto overflow-auto">
              <button
                className=" top-3 right-3 text-gray-900 text-xl font-semibold"
                onClick={() => {
                  setSubmitDisabled(false);
                  setIsOpen(false);
                  setInputValue([props.quiz.title, props.quiz.description])
                }}
              >
                &times;
              </button>
              <div className="flex flex-col space-y-2">
              <label>Quiz Title</label>
                <input
                    type='text'
                    value={inputValue[0]}
                    onChange={(event) => handleInputChange(0, event)}
                    />
                <label>Quiz Description</label>
                <textarea
                  type="text"
                  value={inputValue[1]}
                  onChange={(event) => handleInputChange(1, event)}
                  className="border border-gray-300 p-2 rounded"
                />
                  <button
                    disabled={submitDisabled}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      handlePost(props.quiz.id, props.quiz );
                    }}
                  >
                    Make Change
                  </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuizCardEdit;
