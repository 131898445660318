import React from "react";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import { useState } from "react";
import { useAuth } from "../../util/auth";
import QuizWeekCard from "./QuizWeekCard";
import VideoWeekCard from "./VideoWeekCard";
import ReadingWeekCard from "./ReadingWeekCard";
import AboutWeekCard from "./AboutWeekCard";
import WeekProgressBar from "./WeekProgressBar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../Button";
import SectionNavigationCard from "./SectionNavigationCard";
import { updateWeekCompletion, useWeekCompletion, useSectionDataByWeekIndexandOwner, useSectionsByWeek, useReadingsByWeekandSection, useQuizzesByWeekandSection, useVideosByWeekandSection, useQuizScoresByOwnerWeekSection, useSectionDataByWeekandOwner, updateSectionCompletion } from "../../util/db";
import { Link } from "../../util/router";
import SectionHeaderEdit from "./SectionHeaderEdit";
function WeekSection(props) {

  const {weekNumber} = useParams()
  const {sectionNumber} = useParams()
  const auth = useAuth()
  const [disabled, setDisabled] = useState(false)

    // Trigger your data fetching here based on the new weekNumber
    const {
      data: weeksData,
      status: weeksDataStatus,
      error: weeksDataError
    } = useWeekCompletion(auth.user.uid)

    const {
      data: sectionData,
      status: sectionDataStatus,
      error: sectionDataError
    } = useSectionDataByWeekandOwner(parseInt(weekNumber), auth.user.uid)
    const {
      data: sections,
      status: sectionsStatus,
      error: sectionsError
    } = useSectionsByWeek(parseInt(weekNumber))
    const {
      data: quizzes,
      status: quizzesStatus,
      error: quizzesError,
    } = useQuizzesByWeekandSection(parseInt(weekNumber), parseInt(sectionNumber));
    const {
      data: readings,
      status: readingsStatus,
      error: readingsError,
    } = useReadingsByWeekandSection(parseInt(weekNumber), parseInt(sectionNumber));
      const {
      data: videos,
      status: videosStatus,
      error: videosError,
    } = useVideosByWeekandSection(parseInt(weekNumber), parseInt(sectionNumber));

    const {
      data: individualWeekData,
      status: individualWeekDataStatus,
      error: individualWeekDataError,
    } = useSectionDataByWeekIndexandOwner(parseInt(weekNumber),parseInt(sectionNumber), auth.user.uid);
    const {
      data: quizScores,
      status: quizScoresStatus,
      error: quizScoresError,
    } = useQuizScoresByOwnerWeekSection(auth.user.uid, parseInt(weekNumber),parseInt(sectionNumber));
  var videoID = 0
  var quizID = 0
  var readingID = 0
  var elementsArray = []

  function completeWeek(old,oldweekData, weekNumber) {
    var quizArray = []
    var boolArray = []
    var count = 0
    var shouldValidate = false
    sections[sectionNumber]?.elements?.map((each, index) => {
      if (each == "quiz") {
        quizArray.push(count)
        boolArray.push(false)
        count +=1
        return 0
      }
    })
    quizScores.map((score, index) => {
      if (score.score >=80 ) {
        boolArray[score.index] = true
      }
    })
    

    if (!boolArray.includes(false)) {
      shouldValidate = true
    }
    var oldData = old
    var oldWeekData = oldweekData
    oldData[sectionNumber] = "Complete"
    
    if (shouldValidate === true) {
      if (oldData.includes("Upcoming") || oldData.includes("In Progress")) {
        console.log("Week Not Completed yet.")
      } else {
        oldWeekData[weekNumber-1] = "Complete"
        updateWeekCompletion(auth.user.uid, {array: oldWeekData})
        console.log("Week is Completed")
      }
      updateSectionCompletion(sectionData[0].id, {array: oldData, owner: auth.user.uid, week: weekNumber})
    } else {
      alert("You need to pass all the quizzes in this section first.")
    }
  }
  if (sectionsStatus === "success" && sections && sections.length>0 && individualWeekDataStatus=== "success" && individualWeekData && individualWeekData.length === 1) {
    var quizcount=0
    var videocount = 0
    var readingcount = 0
    elementsArray = sections[sectionNumber]?.elements?.map((type, index) => {
      if (type === "quiz"){
        quizcount +=1
        return {name: `Step ${index}`, href: `/quiz/${weekNumber}/${sectionNumber}/${quizcount-1}`, status: individualWeekData[0].array[index], item: type, label: `Quiz ${quizcount}`}
    } else if (type === "reading") {
        readingcount +=1
      return {name: `Step ${index}`, href: `/reading/${weekNumber}/${sectionNumber}/${readingcount-1}`, status: individualWeekData[0].array[index], item: type, label: `Reading ${readingcount}`}
    } else {
      videocount +=1
      return {name: `Step ${index}`, href: `/video/${weekNumber}/${sectionNumber}/${videocount-1}`, status: individualWeekData[0].array[index], item: type, label: `Video ${videocount}`}
    }
  })
  }

  return (
    <>
        
      {((sectionsStatus === "success" && sections && sections.length > 0)
      &&(readingsStatus==="success" && readings && readings.length>0)
      &&(weeksDataStatus==="success" && weeksData)
      &&(individualWeekDataStatus==="success" && individualWeekData && individualWeekData.length === 1)
      &&(quizScoresStatus==="success" && quizScores)
      &&(sectionDataStatus==="success" && sectionData && sectionData.length===1)

      )&&(
        <>
        <Section
        size={props.size}
        bgColor={props.bgColor}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        textColor={props.textColor}
      >
        <div className="container">
          {<>
            <SectionHeader
            title={`Week ${weekNumber}: Section ${parseInt(sectionNumber)+1}: ${sections[sectionNumber]?.title}`}
            subtitle={sections[sectionNumber]?.description}
            strapline={props.strapline}
            className="text-center"
          />
          {(auth.user.uid==="ZQ8b2qGAi6XT8cv50wFqrU9Mlpo1" || 
          auth.user.uid === "I5oYuWNUG6bJPzKpIKyBqnakNJj1" ||
          auth.user.uid == "rP2px073vOdelMx2akcvhvWjeMk2")&&(
          <SectionHeaderEdit section = {sections[0]}/>
          )}
          </>
          }
        </div>
      </Section>
      <Section
      size={props.size}
      bgColor={"white"}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      >
      <div className="container">
        <div className="grid grid-cols-1">
          <SectionNavigationCard current = {parseInt(sectionNumber)} weekNumber = {weekNumber} elements = {sections}/>
          <div className="grid grid-cols-2">
          {!(sectionNumber === "0")?<Button component = {Link} to={`/week/${weekNumber}/${parseInt(sectionNumber)-1}`} className="mx-2">Previous Section</Button>:<Button className = "mx-2" disabled = {true}>Previous Section</Button>}
          {!(parseInt(sectionNumber) === (sections.length-1))?<Button component = {Link} to={`/week/${weekNumber}/${parseInt(sectionNumber)+1}`} className="mx-2">Next Section</Button>:<Button className = "mx-2" disabled = {true}>Next Section</Button>}
          </div>
          <WeekProgressBar elements = {elementsArray}/>
          <AboutWeekCard data = {sections[sectionNumber]} week = {weekNumber}/>
          {sections[sectionNumber]?.elements?.map((title, key) => {
            
            if (title === "video" && (videosStatus==="success" && videos && videos.length>0)
          ) {
              videoID +=1
              return <VideoWeekCard key = {key}week = {weekNumber} indexCount = {videoID-1} data = {videos[videoID-1]} section = {sectionNumber}/>
            }
            else if (title === "reading") {
              readingID +=1
              return <ReadingWeekCard data = {readings[readingID-1]} key = {key}week = {weekNumber} indexCount = {readingID-1} section = {sectionNumber}/>
            }
            else if (title === "quiz" && (quizzesStatus === "success" && quizzes && quizzes.length >0)) {
              quizID +=1
              return <QuizWeekCard key = {key}week = {weekNumber} data={quizzes[quizID-1]} indexCount = {quizID-1} section = {sectionNumber}/>
            }
          })}
          <Button disabled = {(sectionData[0].array[sectionNumber] ==="Complete" || disabled) } onClick = {() => {
            setDisabled(true)
            completeWeek(sectionData[0].array,weeksData.array, parseInt(weekNumber))}

            }> Mark Section Complete</Button>
        </div>
      </div>
      </Section>
        </>
      )}
    </>
  );
}

export default WeekSection;
