import React from "react";
import Button from "../Button";
import { Link } from "../../util/router";
import { useAuth } from "../../util/auth";
import QuizCardEdit from "./QuizCardEdit";

function QuizWeekCard(props) {
    const title = props.data?.title ?? 'Default Title'; // If props.data is undefined, or title is not present, use 'Default Title'
    const description = props.data?.description ?? 'Default description'; // Same for description
    const auth = useAuth()
 
    return (
    <div className="p-4 w-full">
        <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
            <h2 className="mb-4">Quiz</h2>
            <h3>
            {title}
            </h3>
            <div className="container">
                <div className="grid grid-cols-2">
                    <p className="px-2">
                     {description}
                    </p>
                    
                    <div>
                    <p>You can take a quiz when you feel like you have mastered the material. Please note, the quiz result will be logged.</p>
                    <Button
                        component={Link}
                        to={
                            `/quiz/${props.week}/${props.section}/${props.indexCount}`
                        }
                        size="lg"
                        variant={"light"}
                        isBlock={true}
                    >Take Quiz</Button>
                    {(auth.user.uid==="ZQ8b2qGAi6XT8cv50wFqrU9Mlpo1" || 
                        auth.user.uid === "I5oYuWNUG6bJPzKpIKyBqnakNJj1" ||
                        auth.user.uid == "rP2px073vOdelMx2akcvhvWjeMk2")&&(
                        <QuizCardEdit quiz = {props.data}/>)}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default QuizWeekCard;