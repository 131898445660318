import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
import imageOneSrc from "../assets/maskedWoman.jpg"
import imageTwoSrc from "../assets/tools.jpg"


function AboutPage(props) {

  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        title={
          <>
            We are <span className="font-light">DATC</span>
          </>
        }
        subtitle="Transform your passion for dental care into a fulfilling career with our comprehensive dental assistant training program, equipping you with the skills and knowledge needed to excel in the thriving field of oral healthcare."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        leftImage={imageTwoSrc}
        rightImage={imageOneSrc}
      />
      <StatsSection
        title="Simple Yet Powerful"
        subtitle="A comprehensive course without all the hassle, with all the results."
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      {/* <TeamBiosSection
        title="Meet our amazing team"
        subtitle="They are working nonstop behind the scenes to help you build better products, web services and websites."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      /> */}
      <CtaSection
        title={
          <>
            Create an account <span className="text-blue-600">today</span>!
          </>
        }
        subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default AboutPage;
