import React from "react";
import AboutCardEdit from "./AboutCardEdit";
import { useAuth } from "../../util/auth";


function AboutWeekCard(props) {
    const auth = useAuth()
    return (
    <div className="p-4 w-full">
        <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
            <h2 className="mb-4">Introduction to This Unit</h2>
            <div className="container">
                <div className="grid grid-cols-1">
                    <p>
                        {props.data?props.data.aboutParagraph:"Loading"}
                    </p>
                    {(auth.user.uid==="ZQ8b2qGAi6XT8cv50wFqrU9Mlpo1" || 
                        auth.user.uid === "I5oYuWNUG6bJPzKpIKyBqnakNJj1" ||
                        auth.user.uid == "rP2px073vOdelMx2akcvhvWjeMk2")&&(
                        <AboutCardEdit section = {props.data}/>
                    )}
                </div>
            </div>
        </div>
    </div>
    )
}

export default AboutWeekCard;