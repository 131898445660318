import React from "react";
import Button from "../Button";
import { Link } from "../../util/router";
import ReadingCardEdit from "./ReadingCardEdit";
import { useAuth } from "../../util/auth";



function ReadingWeekCard(props) {
    const auth = useAuth()
    return (
        <>
        {(props.data)&&(
            <div className="p-4 w-full">
            <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
                <h2 className="mb-4">{props.data.title}</h2>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <p>
                            {props.data.description}
                        </p>
                        {(auth.user.uid==="ZQ8b2qGAi6XT8cv50wFqrU9Mlpo1" || 
                        auth.user.uid === "I5oYuWNUG6bJPzKpIKyBqnakNJj1" ||
                        auth.user.uid == "rP2px073vOdelMx2akcvhvWjeMk2")&&(
                        <ReadingCardEdit reading = {props.data}/>)}
                        <Button
                            component={Link}
                            to={
                                `/reading/${props.week}/${props.section}/${props.indexCount}`
                            }
                            size="lg"
                            variant={"light"}
                            isBlock={true}
                        >Read Text</Button>
                    </div>
                </div>
            </div>
        </div>
        )}
    </>
    )
}

export default ReadingWeekCard;