import React from "react";
import Button from "../Button";
import { Link } from "../../util/router";



function VideoWeekCard(props) {
    return (
    <div className="p-4 w-full">
        <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
            <h2 className="mb-4">Video</h2>
            <div>
                <h3>
                {props.data.title}
                </h3>
            </div>
            <div className="container">
                <div className="grid grid-cols-2">
                    <p>
                    {props.data.description}
                    </p>
                    <div>
                    <p>This will take you to a page with a video explaining: {props.data.title}</p>
                    <Button
                        component={Link}
                        to={
                            `/video/${props.week}/${props.section}/${props.indexCount}`
                        }
                        size="lg"
                        variant={"light"}
                        isBlock={true}
                    >Watch Video</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default VideoWeekCard;