import React from'react';
import Meta from '../components/Meta';
import { requireAuth } from "./../util/auth";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ReadingSection from '../components/Custom/ReadingSection';
import FeedBack from '../components/Custom/FeedBack';

function ReadingPage(props) {
  let {weekNumber} = useParams();
  return (
    <>
      <Meta title="Reading" />
      <FeedBack/>

      <ReadingSection 
        title={`Week ${weekNumber}: NEED TO FETCH WITH FIRESTORE`}
        subtitle="DESCRIPTION OF WEEK"
        strapline="Dental Assistant Training Center"
        size="sm" //
        bgColor="bg-light_pink"
        paragraphs = {["test", "test2", "test3"]}
      /> 
    </>
  );
}

export default requireAuth(ReadingPage);
