import React, { useState } from 'react';
import { useReadingOnce, updateReading, updateQuestion } from '../../util/db';

const QuestionEdit = (props) => {
  var olddata = {}
  const [isOpen, setIsOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [inputValue, setInputValue] = useState([props.question.question, props.question.answerOne,props.question.answerTwo,
    props.question.answerThree,props.question.answerFour,props.question.answerFive,props.question.correctAnswer]);  // Initialize state with props.paragraph


    const handleInputChange = (index, event) => {
        const newInputValues = [...inputValue]; // Clone the existing array
        newInputValues[index] = event.target.value; // Update the value at the specific index
        setInputValue(newInputValues); // Set the new array to state
      };

  async function handlePost(id, input, old) {
    var olddata = {
        question: input[0],
        answerOne: input[1],
        answerTwo: input[2],
        answerThree: input[3],
        answerFour: input[4],
        answerFive: input[5],
        section: old.section,
        index: old.index,
        week: old.week,
        correctAnswer: input[6]
    };
    setSubmitDisabled(true);
    await updateQuestion(id, olddata);
    alert('Successfully Changed!');
    setIsOpen(false);
    setSubmitDisabled(false)
  }

  return (
    <div className="relative">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        Edit Question
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
            <div className="bg-white shadow-lg rounded p-6 w-full max-w-6xl h-full md:h-auto overflow-auto">
              <button
                className=" top-3 right-3 text-gray-900 text-xl font-semibold"
                onClick={() => {
                  setSubmitDisabled(false);
                  setIsOpen(false);
                  setInputValue([props.question.question, props.question.answerOne,props.question.answerTwo,
                    props.question.answerThree,props.question.answerFour,props.question.answerFive,props.question.correctAnswer])
                }}
              >
                &times;
              </button>
              <div className="flex flex-col space-y-2">
                <label>Question</label>
                <input
                  type="text"
                  value={inputValue[0]}
                  onChange={(event) => handleInputChange(0, event)}
                  className="border border-gray-300 p-2 rounded"
                />
                <label>Answer One</label>
                <input
                  type="text"
                  value={inputValue[1]}
                  onChange={(event) => handleInputChange(1, event)}
                  className="border border-gray-300 p-2 rounded"
                />
                <label>Answer Two</label>
                <input
                  type="text"
                  value={inputValue[2]}
                  onChange={(event) => handleInputChange(2, event)}
                  className="border border-gray-300 p-2 rounded"
                />
                <label>Answer Three</label>
                <input
                  type="text"
                  value={inputValue[3]}
                  onChange={(event) => handleInputChange(3, event)}
                  className="border border-gray-300 p-2 rounded"
                />
                <label>Answer Four</label>
                <input
                  type="text"
                  value={inputValue[4]}
                  onChange={(event) => handleInputChange(4, event)}
                  className="border border-gray-300 p-2 rounded"
                />
                <label>Answer Five</label>
                <input
                  type="text"
                  value={inputValue[5]}
                  onChange={(event) => handleInputChange(5, event)}
                  className="border border-gray-300 p-2 rounded"
                />
                <label>{"Correct Answer (a,b,c,d,e)"}</label>
                <input
                  type="text"
                  value={inputValue[6]}
                  onChange={(event) => handleInputChange(0, event)}
                  className="border border-gray-300 p-2 rounded"
                />
                  <button
                    disabled={submitDisabled}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      handlePost(props.question.id, inputValue, props.question);
                    }}
                  >
                    Make Change
                  </button>
                
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionEdit;
