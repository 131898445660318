import React from "react";
import Button from "../Button";
import { Link } from "../../util/router";
import { useAuth } from "../../util/auth";
import { deleteFeedback } from "../../util/db";

function FeedbackCard(props) {
    const email = props.owner ?? 'Default Title'; // If props.data is undefined, or title is not present, use 'Default Title'
    const description = props.description ?? 'Default description'; // Same for description
    const url = props.url
    return (
    <div className="p-4 w-full">
        <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
            <h3>
            {email}
            </h3>
            <h4>Feedback on the page {`datc.online/${url}`}</h4>
            <div className="container">
                <div className="grid grid-cols-1">
                    <p className="px-2">
                     {description}
                    </p>
                    <div className="grid grid-cols-2">
                    <Button
                        component={Link}
                        to={
                            `/${url}`
                        }
                        size="lg"
                        variant={"light"}
                        isBlock={true}
                    >Visit Location</Button>
                    <Button
                        to={
                            `/${url}`
                        }
                        size="lg"
                        className = "bg-red-500 mx-10 hover:bg-red-600"
                        isBlock={true}
                        onClick={() => {
                            deleteFeedback(props.id)
                        }}
                    >Delete Feedback</Button>
                </div>
                </div>

            </div>
        </div>
    </div>
    )
}

export default FeedbackCard;