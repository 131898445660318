import React from'react';
import Meta from '../components/Meta';
import { requireAuth } from "./../util/auth";
import WeekSection from "./../components/Custom/WeekSection"
import WorkingSideBar from '../components/Custom/WorkingSidebar';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FeedBack from '../components/Custom/FeedBack';

function WeekPage(props) {
  let {weekNumber} = useParams();
  return (
    <>
      <Meta title="Course" />
      <FeedBack/>

      <div className="flex flex-row"> {/* Flex container */}
        <div className="w-1/5 min-w-max"> {/* Sidebar with width control */}
          <WorkingSideBar weekNumber = {weekNumber}/>
        </div>
        <div className="flex-1"> {/* Main content area taking up the remaining space */}
          <WeekSection 
            title={`Week ${weekNumber}: NEED TO FETCH WITH FIRESTORE`}
            subtitle="DESCRIPTION OF WEEK"
            strapline="Dental Assistant Training Center"
            size="sm"
            bgColor="bg-light_pink"
          />
        </div>
      </div>
    </>
  );
}

export default requireAuth(WeekPage);
