import React from'react';
import Meta from '../components/Meta';
import { requireAuth } from "./../util/auth";
import VideoSection from "./../components/Custom/VideoSection"
import FeedBack from '../components/Custom/FeedBack';
function VideoPage(props) {

  return (
    <>
      <Meta title="Video" />
      <FeedBack/>
      <VideoSection
      strapline="Dental Assistant Training Center"
      size="sm" //
      bgColor="bg-light_pink"/>
    </>
  );
}

export default requireAuth(VideoPage);
