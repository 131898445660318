import React from 'react';
import Meta from '../components/Meta';
import { requireAuth } from "./../util/auth";
import QuizSection from "./../components/Custom/QuizSection";
import { useParams } from 'react-router-dom';
import FeedBack from '../components/Custom/FeedBack';
function QuizPage(props) {
  let { weekNumber } = useParams();

  return (
    <>
      <Meta title="Quiz" />
      <FeedBack/>

      <div className="flex flex-row"> {/* Flex container */}
        <div className="flex-1"> {/* Main content area taking up the remaining space */}
          <QuizSection 
            strapline="Dental Assistant Training Center"
            size="sm"
            bgColor="bg-light_pink"
          />
        </div>
      </div>
    </>
  );
}

export default requireAuth(QuizPage);