import React from "react";


function VideoCard(props) {

      

    return (
        <div className="p-4 w-full">
        <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
          <div className="container mx-auto">
            <div className="flex justify-center">
              <div className="video-responsive" style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
                <iframe
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  src={`https://www.youtube.com/embed/${props.code}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default VideoCard;